import React, { useState, useEffect, useRef } from 'react';
import './App.css';

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [location, setLocation] = useState({ letter: '', part1: '', part2: '', part3: '', part4: '' });
  const [selectedReference, setSelectedReference] = useState('');
  const [saveStatus, setSaveStatus] = useState('');
  const [fixedLocation, setFixedLocation] = useState(false);
  const [foundLocation, setFoundLocation] = useState(''); 
  const part1Ref = useRef(null);
  const part2Ref = useRef(null);
  const part3Ref = useRef(null);
  const part4Ref = useRef(null);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isWeightInput, setIsWeightInput] = useState(false);

  const [bruto, setBruto] = useState('');
  const [liquido, setLiquido] = useState('');

  useEffect(() => {}, []);

  const handleLogin = () => {
    fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setIsAuthenticated(true);
        } else {
          alert('Usuário ou senha incorretos');
        }
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  };

  const handleSearch = (event) => {
    const value = event.target.value.toUpperCase();
    setSearchTerm(value);

    if (value === ' ') {
      setSuggestions([]);
    } else {
      fetch('/Referencias.txt')
        .then(response => response.text())
        .then(text => {
          const references = text.split('\n').map(line => line.trim()).filter(line => line !== '');
          const filteredSuggestions = references.filter(reference => reference.match(value));
          setSuggestions(filteredSuggestions);
        });
    }
  };

  const handleSelectReference = (reference) => {
    setSelectedReference(reference);
    setSearchTerm(reference);
    setSuggestions([]);
    setFoundLocation('');

    const fixedPrefixes = ["02-", "05-", "09-", "1-", "2-", "3-", "5-", "6-", "FUN-", "M90-", "M03-", "M09-", "M62-", "EQ-", "M64-", "08-", "07-", "M69-", "M65-", "M61-", "MEL-", "MD-", "DCU-"];
    const shouldFixLocation = fixedPrefixes.some(prefix => reference.startsWith(prefix));
    setFixedLocation(shouldFixLocation);

    fetch('/FinalDocument.txt')
      .then(response => response.text())
      .then(text => {
        const entries = text.split('\n').map(line => line.trim()).filter(line => line !== '');
        const existingEntry = entries.find(entry => entry.startsWith(`${reference};`));

        if (existingEntry) {
          const existingLocation = existingEntry.split(';')[1];
          setFoundLocation(existingLocation);
        }
      })
      .catch(error => {
        console.error('Erro ao ler o arquivo:', error);
      });
  };

  const handleSaveLocation = () => {
    const { letter, part1, part2, part3, part4 } = location;
    const paddedPart1 = part1.padStart(2, '0');
    const paddedPart2 = part2.padStart(2, '0');
    const paddedPart3 = part3.padStart(2, '0');
    const paddedPart4 = part4.padStart(2, '0');
  
    let locationString = `${letter}-${paddedPart1}-${paddedPart2}-${paddedPart3}`;
    if (part4) {
      locationString += `_${paddedPart4}`;
    }
    const data = `${selectedReference};A${locationString};${fixedLocation ? 'true' : 'false'}`;
    console.log(data);
  
    fetch('/FinalDocument.txt')
      .then(response => response.text())
      .then(text => {
        const entries = text.split('\n').map(line => line.trim()).filter(line => line !== '');
        const existingEntry = entries.find(entry => entry.startsWith(`${selectedReference};`));
  
        if (existingEntry) {
          const existingLocation = existingEntry.split(';')[1];
          const confirmMessage = `Já foi atribuida a localização: ${existingLocation} á referência: ${selectedReference}. \n\nPara gravar a nova localização click em OK. Para manter a que está no sistema Click em CANCELAR`;
          if (!window.confirm(confirmMessage)) {
            return;
          }
        }
  
        fetch('/appendFile', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data }),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('A resposta da rede não foi boa');
            }
            return response.json();
          })
          .then(result => {
            console.log('Arquivo atualizado com sucesso:', result);
            setSaveStatus('Salvou');
            setTimeout(() => {
              setSaveStatus('');
              setSelectedReference('');
              setSearchTerm('');
              setLocation({ letter: '', part1: '', part2: '', part3: '', part4: '' });
              setFixedLocation(false);
              setFoundLocation('');
            }, 1500);
          })
          .catch(error => {
            console.error('Erro ao atualizar o arquivo:', error);
            setSaveStatus('Erro ao salvar');
          });
      })
      .catch(error => {
        console.error('Erro ao ler o arquivo:', error);
      });
  };

  const handleSaveWeight = (bruto, liquido) => {
    const formattedBruto = bruto.toString().replace('.', ".");
    const formattedLiquido = liquido ? liquido.toString().replace('.', ",") : formattedBruto;
    const data = {
      reference: selectedReference,
      bruto: formattedBruto,
      liquido: formattedLiquido,
    };
    console.log(data);
  
    fetch('/FinalDocument.txt')
      .then(response => response.text())
      .then(text => {
        const entries = text.split('\n').map(line => line.trim()).filter(line => line !== '');
        const existingEntry = entries.find(entry => entry.startsWith(`${selectedReference};`));
  
        if (existingEntry) {
          const existingLocation = existingEntry.split(';')[1];
          const confirmMessage = `Já foi atribuida a localização: ${existingLocation} á referência: ${selectedReference} . \n Pretende gravar a nova localização ou manter a que está no sistema?`;
          if (!window.confirm(confirmMessage)) {
            return;
          }
        }
  
        fetch('/appendWeightFile', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('A resposta da rede não foi boa');
            }
            return response.json();
          })
          .then(result => {
            console.log('Arquivo de peso atualizado com sucesso:', result);
            setSaveStatus('Salvou');
            setTimeout(() => {
              setSaveStatus('');
              setSelectedReference('');
              setSearchTerm('');
              setBruto('');
              setLiquido('');
            }, 1500);
          })
          .catch(error => {
            console.error('Erro ao atualizar o arquivo de peso:', error);
            setSaveStatus('Erro ao salvar');
          });
      })
      .catch(error => {
        console.error('Erro ao ler o arquivo:', error);
      });
  };

  const handleLocationChange = (part, value) => {
    if (part === 'letter') {
      const formattedValue = value.toUpperCase().replace(/[^A-Z]/g, '');
      setLocation({ ...location, [part]: formattedValue });

      if (formattedValue.length === 1) {
        part1Ref.current.focus();
      }
    } else {
      const formattedValue = value.replace(/\D/g, '').slice(0, 2);
      setLocation({ ...location, [part]: formattedValue });

      switch (part) {
        case 'part1':
          if (formattedValue.length === 2) {
            part2Ref.current.focus();
          }
          break;
        case 'part2':
          if (formattedValue.length === 2) {
            part3Ref.current.focus();
          }
          break;
        case 'part3':
          if (formattedValue.length === 2) {
            part4Ref.current.focus();
          }
          break;
        default:
      }
    }
  };

  const handleCheckboxChange = () => {
    setFixedLocation(!fixedLocation);
  };

  const renderLocationInput = () => (
    <div>
      <h1>Procurar Referencias</h1>
      <input
        type="ref"
        placeholder="Procurar Referencia..."
        value={searchTerm}
        onChange={handleSearch}
      />
      {suggestions.length > 0 && (
        <ul>
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSelectReference(suggestion)}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}
      {selectedReference && (
        <div>
          Referencia Selecionada: {selectedReference}
          {foundLocation && (
            <div>Localização Encontrada: {foundLocation}</div>
          )}
          <div>
            <input
              type="text"
              maxLength="1"
              value={location.letter}
              onChange={(e) => handleLocationChange('letter', e.target.value)}
            />
            -
            <input
              type="text"
              maxLength="2"
              value={location.part1}
              onChange={(e) => handleLocationChange('part1', e.target.value)}
              ref={part1Ref}
              className="location-part"
            />
            -
            <input
              type="text"
              maxLength="2"
              value={location.part2}
              onChange={(e) => handleLocationChange('part2', e.target.value)}
              ref={part2Ref}
              className="location-part"
            />
            -
            <input
              type="text"
              maxLength="2"
              value={location.part3}
              onChange={(e) => handleLocationChange('part3', e.target.value)}
              ref={part3Ref}
              className="location-part"
            />
            _
            <input
              type="text"
              maxLength="2"
              value={location.part4}
              onChange={(e) => handleLocationChange('part4', e.target.value)}
              ref={part4Ref}
              className="location-part"
            />
          </div>
          <div>
            <label>
              Localização Fixa
              <input
                type="checkbox"
                checked={fixedLocation}
                onChange={handleCheckboxChange}
              />
            </label>
          </div>
          <button onClick={handleSaveLocation}>Gravar</button>
          {saveStatus && <div className="save-status">{saveStatus}</div>}
        </div>
      )}
    </div>
  );

  const renderWeightInput = () => {
    const handleBrutoChange = (e) => {
      const value = e.target.value;
      setBruto(value);
    };

    const handleLiquidoChange = (e) => {
      const value = e.target.value;
      setLiquido(value);
    };

    const handleSave = () => {
      const formattedBruto = parseFloat(bruto);
      const formattedLiquido = liquido ? parseFloat(liquido) : formattedBruto;
      handleSaveWeight(formattedBruto, formattedLiquido);
    };

    return (
      <div>
        <h1>Procurar Referencias para Peso</h1>
        <input
          type="ref"
          placeholder="Procurar Referencia..."
          value={searchTerm}
          onChange={handleSearch}
        />
        {suggestions.length > 0 && (
          <ul>
            {suggestions.map((suggestion, index) => (
              <li key={index} onClick={() => handleSelectReference(suggestion)}>
                {suggestion}
              </li>
            ))}
          </ul>
        )}
        {selectedReference && (
          <div>
            Referencia Selecionada: {selectedReference}
            <div>
              <input
                type="ref"
                value={bruto}
                onChange={handleBrutoChange}
                placeholder="Peso Bruto (g)"
              />
              <input
                type="ref"
                value={liquido}
                onChange={handleLiquidoChange}
                placeholder="Peso Liquido (g)"
              />
            </div>
            <button onClick={handleSave}>Gravar Peso</button>
            {saveStatus && <div className="save-status">{saveStatus}</div>}
          </div>
        )}
      </div>
    );
  };

  if (!isAuthenticated) {
    return (
      <div className="Login">
        <div className="login-container">
          <h1>Login</h1>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>
            <input
              type="checkbox"
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
            />
            Show Password
          </label>
          <button onClick={handleLogin}>Login</button>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      {isAuthenticated ? (
        <>
          <button onClick={() => setIsWeightInput(false)}>Localização</button>
          <br></br>
          <button onClick={() => setIsWeightInput(true)}>Peso</button>
          {isWeightInput ? renderWeightInput() : renderLocationInput()}
        </>
      ) : (
        <div className="Login">
          <div className="login-container">
            <h1>Login</h1>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>
              <input
                type="checkbox"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />
              Show Password
            </label>
            <button onClick={handleLogin}>Login</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
